import packageJson from "../../../package.json";

export default function Aktualizacja() {
	const date = packageJson.update;
	const formattedDate = new Date(date).toLocaleDateString("pl-PL", {
		day: "numeric",
		month: "long",
		year: "numeric",
	});

	return (
		<time id="a11y-data-aktualizacja" dateTime={date}>
			<strong>{formattedDate}</strong>
		</time>
	);
}
