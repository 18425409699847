export default function Publikacja(props) {
	const date = props.children;
	const formattedDate = new Date(date).toLocaleDateString("pl-PL", {
		day: "numeric",
		month: "long",
		year: "numeric",
	});

	return (
		<time id="a11y-data-deklaracja-publikacja" dateTime={date}>
			<strong>{formattedDate}</strong>
		</time>
	);
}
