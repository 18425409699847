import Section from "../Section";

export function Sekcja({ lightdark, children, id }) {
	return (
		<Section id={id} lightdark={lightdark}>
			{children}
		</Section>
	);
}

export default Sekcja;
